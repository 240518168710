.newCardStyle {
  object-fit:cover;
  -webkit-box-reflect:below 1px linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.092));
  transform-origin:center;
  transform:perspective(800px) rotateY(25deg);
  transition:0.4s;
}

.newCardStyle {
  opacity:.7;
}
.newCardStyle:hover {
  transform:perspective(800px) 	rotateY(0deg);
  opacity:1;
  cursor: pointer;
  border-bottom: 1px solid #0fff9b;
  transition: .3s all ease;
}
.fp-head{
  /* font-family: 'LEIXO-DEMO'; */
  font-family: 'Big-john';
  font-size: 30px; 
  color: #fff;
  background-color: rgba(0, 0, 0, 0.141);
  padding: 4px 6px;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .newCardStyle {
    object-fit:cover;
    -webkit-box-reflect:below 1px linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.092));
    transform-origin:center;
    transform: rotateY(0);
    transition:0.4s;
  }
  .newCardStyle {
    opacity:1;
  }
  .fp-head{
    /* font-family: 'LEIXO-DEMO'; */
    font-family: 'Big-john';
    font-size: 26px; 
    color: #fff;
    background-color: rgba(0, 0, 0, 0.046);
    padding: 4px 6px;
    text-decoration: none;
}
}