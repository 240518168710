
.input-style{
    display: block;
    width: 80% !important;
    padding: .375rem .75rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff0 !important;
    background-clip: padding-box;
    border: 3px solid #0fff9b !important;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0 !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: 'LEIXO-DEMO';
    font-size: 20px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #fff !important;
}
.form-group{
    margin-bottom: 26px !important;
}
.spaceConnect{
    margin-top: 60px;
    height: 75vh;
}
.txt-area {
    /* height: 200px!important; */
    padding-bottom: 110px;
}

.btn-connect{
    border: 3px solid #0fff9b !important ;
    padding: 4px 60px !important;
    color:#fff ;
    font-family: 'LEIXO-DEMO';
    font-size: 20px !important; 
    background-color: #fff0 !important;
}
.btn-connect:hover{
    border: 3px solid #0fff9b !important ;
    padding: 4px 60px;
    color:rgb(0, 0, 0) !important;
    font-family: 'LEIXO-DEMO';
    font-size: 20px !important; 
    background-color: #0fff9b !important;
    transition: .3s ease-in;
}
/* .btn-connect{
    border: 3px solid #0fff9b !important ;
    padding: 4px 72px !important;
    color:#000 !important ;
    font-family: 'LEIXO-DEMO';
    font-size: 20px !important; 
    background-color: #0fff9b !important;
}
.btn-connect:hover{
    border: 3px solid #ffffff !important ;
    padding: 4px 72px;
    color:rgb(0, 0, 0) !important;
    font-family: 'LEIXO-DEMO';
    font-size: 20px !important; 
    background-color: #ffffff !important;
    transition: .3s ease-in;
} */
.centre {
    text-align: center;
    margin-top: 24px;
    width: 80%;
}
.copyright{
    color:#0fff9b ;
    font-family: 'LEIXO-DEMO';
    font-size: 10px !important;
    padding-bottom: 20px;
    text-align: left;
    }
.address{
color:#fff ;
font-family: 'LEIXO-DEMO';
font-size: 18px !important; 
text-align: left !important;
margin-bottom: 32px;
line-height: 1.4;
}
.address-div{
padding-top: 20px;
}
.highlight{
color:#0fff9b ;
}
.box-address{
    width: 80%;
    padding-left: 15px;
    text-align: left;
}
.padLeft{
    padding: 0px 0px 0px 15px;
}
.scroll-top{
    right: 89px;
    bottom: 29px;
    z-index: 9999999;
    position: fixed;
    padding: 10px 20px;
}
/* .row_form{
    display:flex;
    flex-direction:column;
    padding:10
} */
.styleConnectCube {
    width: 450px !important;
    margin-top: -40px !important;
    margin-right: 5% !important;
}
@media only screen and (max-width: 768px){
.scroll-top {
    right: 42px !important;
    height: 50px !important;
    bottom: 19px !important;
    z-index: 9999999;
    position: fixed;
    padding: 2px 15px !important;
    width: 50px !important;
}


/* #lottie {
    height: 300px;
} */

.address {
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 17px !important;
}
}

.Digit-style{
  font-family: 'Ariel-Rounded';
}
.desktopView{
    display: block;
  }
  .mobileView{
    display: none !important;
}
@media only screen and (max-width: 600px) {
    .mobileView{
        display: block !important;
    }
    .desktopView{
        display: none !important;
    }
    .input-style{
        display: block;
        width: 100% !important;
        border: 2px solid #0fff9b !important;
    }
    .styleConnectCube{
        width: 310px !important;
    }
    .spaceConnect{
        margin-top: 60px;
        height: auto;
        margin-bottom: 60px;
    }
    .padLeft{
        padding: 0px 15px 0px 15px;
    }
    .centre {
        text-align: center;
        margin-top: 24px;
        width: auto;
    }
    .address{
        text-align: center !important;
    }
    .box-address{
        width: 100% !important;
        padding-left: 0px;
        text-align: center !important;
        margin-bottom: 30px;
    }
    .btn-connect{
        border: 2px solid #0fff9b !important ;
    }
    .btn-connect:hover{
        border: 2px solid #0fff9b !important ;
    }
}