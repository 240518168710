body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
background-color: #000 !important;
background-image: url('../src/Assets/BGWITHNOISE.png');
height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face{
  font-family: 'LEIXO-DEMO';
src:  url('./Assets/leixo/LEIXO-DEMO.ttf') ;
}
@font-face{
  font-family: 'Ariel-Rounded';
src:  url('./Assets/arlrdbd.ttf') ;
}
@font-face {
  font-family: 'Big-john';
  src: url('./Assets/BigJohnPRO-Bold.otf');
}
@font-face {
  font-family: 'Outfit-Regular';
  src: url('./Assets/Outfit-Regular.ttf');
}
