
.about-block {
    padding-top: 150px;
    /* padding-bottom: 79px; */
}
.about-para-txt {
    margin: 0px 15px;
text-align: left;
/* margin-top: 125px; */
}
.about-info {
    color: white;
    font-size: 26px;
    font-family: 'LEIXO-DEMO';
}
.about-para-highlight{
    color: #0fff9b;
}
.head-about{
    text-align: center;
}
.head-about-txt{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 45px;
}

.divider {
    background-color: #0fff9b;
    width: 50%;
    margin-top: 130px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.live-left-txt{
    color: #0fff9b !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 20px;
    /* padding-right: 30px; */
}
.cube-3d{
    position: absolute;
    top: 81px;
    right: -5%;
    /* right: 40%; */
    /* top: 272px; */
}

.services-block{
    margin-left: 15px;
    padding-top: 80px;
    padding-bottom: 80px;
}
.services-head-txt{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 35px;
  }

  .services-head{
    text-align: center;
    margin-bottom: 60px;
}
.service-1{
    text-align: center;
}
.new-border{
    border: 1px solid #0fff9b29;
    margin: 0px 10px !important;
    padding: 30px 30px 83px 30px;
    border-radius: 4px;
}
.services-card{
    padding: 30px !important;
    background-color: transparent !important;
}
.img-style{
    height: 70px !important;
}
.Live-link-style{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 30px;
    text-decoration: none !important;
    /* padding: 5px 50px !important; */
    
  }
  .service-live{
    /* display: flex; */
    text-align: center;
}

.live-left-txt{
    color: #0fff9b !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 20px;
    /* padding-right: 30px; */
}
.new-border-s{
    margin: 0px 10px !important;
    padding: 30px 30px 39px 30px;
    border-radius: 4px;
}
.animateCard{
    border: 1px solid #0fff9b29;
}
/* .animateCard:hover{
    background: #000000;
    border-radius: 4px;
    border: 1px solid #fff;
    -webkit-transform: translate(10px,-10px);
    transform: translate(10px,-10px);
    box-shadow: -10px 10px 0 0 #0fff9b;
    transition: .6s!important;
} */
.new-border-s-n{
    margin: 0px 10px !important;
    padding: 30px 30px 133px 30px;
    border-radius: 4px;
}
.services-card{
    padding: 30px !important;
    background-color: transparent !important;
}
.content-link-style{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 30px;
    text-decoration: none !important;
    text-align: center;
    /* padding: 5px 50px !important; */
  }
  .new-border-s{
    margin: 0px 10px !important;
    padding: 30px 30px 39px 30px;
    border-radius: 4px;
}
.digital-link-style{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    font-weight: lighter;
    font-size: 30px;
    text-decoration: none !important;
    text-align: center;
    /* padding: 5px 50px !important; */
  }

  .client-slider{
    /* background: #fff; */
    height: 220px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.client-slider:before {
    left: 0;
    top: 0;
}
:not(svg) {
    transform-origin: 0 0;
}
.client-slider:after, .client-slider:before {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.client-slider .client-slide-track {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    display: flex;
    width: 8000px;
}
.client-slider .client-slide {
    margin-top: 55px;
    padding: 15px 25px 0 30px;
}
.client-slider:after {
    right: 0;
    top: 0;
    transform: rotate(180deg);
}
@keyframes scroll{
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-5000px);
    }
}

.client-sliderr{
    height: 500px;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.client-sliderr:before {
    left: 0;
    top: 0;
}
:not(svg) {
    transform-origin: 0 0;
}
.client-sliderr:after, .client-sliderr:before {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.client-sliderr .client-slide-trackk {
    -webkit-animation: scrolll 40s linear infinite;
    animation: scrolll 40s linear infinite;
    display: flex;
    width: 8000px;
}
.client-sliderr .client-slidee {
    margin-top: 55px;
    padding: 15px 25px 0 30px;
}
.client-sliderr:after {
    right: 0;
    top: 0;
    transform: rotate(180deg);
}
@keyframes scrolll{
    0% {
        transform: translateX(-5000px);
    }
    
    100% {
        transform: translateX(0px);
    }
}
@media only screen and (max-width: 768px){
    
    .services-head-txt{
        font-size: 30px;
    }
    .about-block{
        padding-top: 223px;
    }
    .about-para-txt{
        padding-top: 80px;
    }
    .scroll-top {
        right: 42px !important;
        height: 50px !important;
        bottom: 19px !important;
        z-index: 9999999;
        position: fixed;
        width: 50px !important;
    }

    
}
.copyright{
    color:#0fff9b ;
    font-family: 'LEIXO-DEMO';
    font-size: 10px !important;
    padding-bottom: 20px;
    text-align: left;
    }
    @media screen and (max-width: 768px) and (min-width: 700px) {
        .cube-3d{
            right: 57%;
        }
}

@media screen and (max-width: 1440px) and (min-width: 1400px) {
.cube-3d{
    right: 3%;
}
}

@media screen and (max-width: 1536px) and (min-width: 1500px) {
.cube-3d{
    right: 9%;
}
}
.scroll-top{
    right: 40px !important;
    height: 65px !important;
    bottom: 29px;
    z-index: 9999999;
    position: fixed;
}
.Digit-style{
    font-family: 'Ariel-Rounded';
  }
  .line-style{
    width: 36%;
    color: #0fff9b;
    /* border: .1px solid; */
    border-bottom: .2px solid #0fff9bad;
    margin-top: -30px;
  }

  /* new */
  .aboutBlock{
    padding: 140px 0px 120px 0px;
  }
  .aboutCube{
    margin-top: -60px !important;
    margin-right: 30px !important;
    position: absolute;
    right: 20%;
  }
  body{
    overflow-x: hidden;
  }
  .desktopView{
    display: block;
  }
  .mobileView{
    display: none !important;
}
  @media only screen and (max-width: 600px) {
    .mobileView{
        display: block !important;
    }
    .desktopView{
        display: none !important;
    }
    .aboutCube{
        margin-top: -140px !important;
        margin-right: 30px !important;
        position: absolute;
        left: -60px !important;
        width: 250px;
      }
      .aboutBlock{
        margin-top: 40px;
      }
      .about-info {
        color: white;
        font-size: 20px;
        font-family: 'LEIXO-DEMO';
    }
    .about-para-txt {
        margin: 0px 0px 0px 15px;
        text-align: left;
    /* margin-top: 125px; */
    }
 }
 