.show-reel {
    position: relative;
    background-color: black;
    height: 75vh;
    min-height: 25rem;
    width: 96%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }
  
  .show-reel video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .show-reel .video-div {
    position: relative;
    z-index: auto;
  }
  
  .show-reel .overlay-wcs {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: auto;
  }
  
  .reel-top{
      padding-top: 60px;
  }
  .reel-bottom{
    padding-bottom: 100px;
}
.heading-featured-projects-txt{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    text-align: center;
    font-weight: lighter;
    font-size: 35px;
    border-bottom: 1px solid #0fff9b ;
    padding-bottom: 5px;
  }
  .feayured-projects-block{
      padding-top: 100px;
      padding-bottom: 100px;
      z-index: 1;
  }
  .card-wrapper-fp {
    perspective: 1000px;
    transform-style: preserve-3d;
}

.card-fp {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    height: 420px;
    padding: 80px 20px 20px 20px;
    max-width: 100%;
    background: #0fff9b;
    border-radius: 5px;
}
.bg-img-1{
    /* background-image: url('../Assets/card-01.jpg'); */
    background-size: cover;
}


.zoom {
  animation: updown 1s ease 0s infinite;
  
}

@keyframes updown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);

  }
  100% {
    transform: translateY(0px);
    }
}
.service-digital{
  font-family: 'LEIXO-DEMO';
  font-size: 20px !important;
  color: #0fff9b;
  text-decoration: none;
  position: absolute;
  bottom: 5px;
  left: 10px;
  background: rgba(0, 0, 0, 0.512);
  padding: 5px;
  border-radius: 5px;
  opacity: 1 !important;
}
.fp-none{
    text-decoration: none !important;
}
.tabbar > p {
  font-size: 24px;
}
.tabbar:hover {
  cursor: pointer;
}
.para-link {
  font-family: 'Big-john';
  font-size: 24px !important; 
  color: #fff;
  text-decoration: underline;
}
.para-link a {
  text-decoration: none;
  color: #0fff9b;
}
.para-link a:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.work-muted {
  position: absolute;
  height: 35px;
  width: 35px;
  right: 5px;
  bottom: 5px;
}
.work-muted:hover{
  cursor: pointer;
}

@media only screen and (max-width: 768px){
  .work-muted {
    height: 30px;
    width: 30px;
  }
.mobileView{
  display: none;
}
    .bg-img-1{
        /* background-image: url('../Assets/card-01.jpg'); */
        background-size: cover;
    }
    .outer {
        position: relative !important;
        margin-left: auto !important;
        width: 40px !important;
        cursor: pointer !important;
        margin-top: 0px !important;
    }
    .m-b{
        margin-bottom: -10px;
    }
    .displayFlexC{
      margin-bottom: 12px !important;
    }
    .title{
        font-family: 'Big-john';
        font-size: 22px !important; 
        color: #0fff9b;
        text-decoration: none;
        text-align: left;
        margin-bottom: 24px;
    }
    .para{
      font-family: 'Outfit-Regular';
        font-size: 16px !important; 
        color: #fff;
        line-height: 134%;
        text-decoration: none;
        text-align: left;
    }
    .para-link {
      font-family: 'Big-john';
      font-size: 20px !important; 
      color: #fff;
    }
    .tabbar > p {
      font-size: 22px;
    }
    .fp-head {
      font-size: 24px;
    }
    .ReactModal__Content.ReactModal__Content--after-open{
      padding: 10px 16px 60px 16px !important;
    }
}
@media only screen and (max-width: 500px){ 
  .tabbar > p {
    font-size: 18px;
  }
   .fp-head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 400px){ 
  .tabbar > p {
    font-size: 16px;
  }
  .fp-head {
    font-size: 16px;
  }
}
.title{
    font-family: 'Big-john';
    font-size: 36px; 
    color: #0fff9b;
    margin-bottom: 30px;
    text-decoration: none;
    text-align: left;
}
.para{
  font-family: 'Outfit-Regular';
    font-size: 22px; 
    color: #fff;
    line-height: 150%;
    text-decoration: none;
    text-align: left;
}
.style-btn{
    text-align: right;
    padding-top: -60px;
}
.service-name{
    font-family: 'LEIXO-DEMO';
    font-size: 20px !important; 
    color: #0fff9b;
    line-height: 134%;
    text-decoration: none;
}
.btn-close{
    padding: 10px 20px 10px 20px;
    border: 1px solid #0fff9b;
    background-color: transparent;
    color: white;
    font-family: 'LEIXO-DEMO';
    font-size: 16px !important; 
}

.outer {
    position: relative;
    margin-left: auto;
    width: 60px;
    cursor: pointer;
  }
  
  .inner {
    width: inherit;
    text-align: center;
  }
  
  label { 
    font-size: 16px; 
    /* line-height: ; */
    /* text-transform: uppercase; */
    color: #fff;
    font-family: 'LEIXO-DEMO';
    transition: all .3s ease-in;
    opacity: 0;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 6px;
  }
  
  .inner:before, .inner:after {
    position: absolute;
    content: '';
    height: 1px;
    width: inherit;
    background: #0fff9b;
    left: 0;
    transition: all .3s ease-in;
  }
  
  .inner:before {
    top: 50%; 
    transform: rotate(45deg);  
  }
  
  .inner:after {  
    bottom: 50%;
    transform: rotate(-45deg);  
  }
  
  .outer:hover label {
    opacity: 1;
  }
  
  .outer:hover .inner:before,
  .outer:hover .inner:after {
    transform: rotate(0);
  }
  
  .outer:hover .inner:before {
    top: 0;
  }
  
  .outer:hover .inner:after {
    bottom: 0;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    position: fixed;
    inset: 0px;
    border: none;
    background-color: rgb(0 0 0 / 90%) !important;
}
.heading-featured-projects {
  display: flex;
  margin-bottom: 40px;
  justify-content: center;
}
.copyright{
  color:#0fff9b ;
  font-family: 'LEIXO-DEMO';
  font-size: 10px !important;
  padding-bottom: 20px;
  text-align: left;
  }
  .scroll-top{
    right: 30px !important;
    height: 65px !important;
    bottom: 29px;
    z-index: 9999999;
    position: fixed;
}

@media only screen and (max-width: 768px){
.scroll-top {
    right: 42px !important;
    height: 50px !important;
    bottom: 19px !important;
    z-index: 9999999;
    position: fixed;
    width: 50px !important;
}
.service-name-modal{
  font-size: 12px !important; 
  padding: 8px 20px !important;
}
}

.Digit-style{
  font-family: 'Ariel-Rounded';
}
.displayFlexC{
  display: flex;
  margin-bottom: 26px;
}
.service-name-modal{
  font-family: 'LEIXO-DEMO';
  font-size: 20px ; 
  color: #ffffff;
  text-decoration: none;
  border: 1px solid #0fff9b;
  padding: 10px 26px;
  border-radius: 4px;
}
.linkT{
  margin-left: 30px;
  margin-top: 8px;
}
.projectImages{
  height: auto;
  max-width: 100%;
  object-fit: contain;
}
.paddingInner{
  padding-left: 32px !important;
}
.ReactModal__Content.ReactModal__Content--after-open{
  padding: 10px 26px 60px 24px !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 1;
}