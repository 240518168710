.brand-logo-style{
    height: 50px !important;
    margin-top: 30px;
    margin-left: 15px;
}
.nav-link{
    color: #fff !important;
    font-family:  'LEIXO-DEMO';
    font-weight: 400;
    font-size: 20px;
    margin-right: 15px;
}
.nav-link:hover{
color: #0fff9b !important;
transition: all .3s ease-in !important;
}
.right-space{
    margin-right: 50px;
}
@media only screen and (max-width: 768px) {
    .right-space{
        margin-right: 0px;
    }
    .m-view-m{
        margin-right: 0px !important;
        z-index: 1;
        text-align: right;
        }
}
.navbar-light .navbar-toggler-icon {
    background-image: url('../Assets/menu.svg') !important;
}
.navbar-toggler {
    font-size: 25px !important;
}
.m-view-m{
margin-right: 50px;
z-index: 1;
text-align: right;
}
.navbar-collapse {
    /* height: 80vh; */
}
.Digit-style{
    font-family: 'Ariel-Rounded';
  }
 
